/* 
* 업무구분: 고객 
* 화 면 명: MSPCM340M 
* 화면설명: New 통합조회 시스템
* 작 성 일: 2024.08.27 
* 작 성 자: chanwoo8.park 
*/
<template>
  <!-- txtSkip class 추가 2024.9.2 :chan -->
  <ur-page-container ref="page" class="msp txtSkip" title="New 통합조회 시스템" type="subpage" :action-type="actionSlot" :show-title="true" @on-header-left-click="fn_HistoryBack">
    <template #frame-header-fixed>
      <!-- 안내문구 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" style="padding: 5px 20px 20px"><!-- stlye 추가 2024.9.4 :chan -->
        <p class="untact-txt mt0 crTy-orange2 fs14" style="line-height: normal;">
          ※ 주의: 이 화면의 고객정보는 '보험계약상담' 목적 外 활용에 대한 주의가 필요합니다.
        </p>
      </ur-box-container>
      <!-- 검색필터 -->
      <ur-box-container alignV="start" direction="column" class="search-result-box overflow-auto" style="background: #fbfbfb;">
        <div class="fexTy1" style="gap: 10px;"><!-- gap 수정 2024.9.2 :chan -->
          <img src="@/assets/img/icon/ico_refresh_b.png" width="24" height="24" alt="" @click="fn_Init" />
          <!-- 여백 너비 수정 2024.9.3 :chan -->
          <div v-if="isMngr" class="select-box fexTy3 bgcolor-2 bdTy12 bd-radius-20 pl5 pr5 pt3 pb3" :class="{ 'bgcolor-4': activeCnsltNo, 'crTy-white': activeCnsltNo }" @click="fn_OpenPopup345">
            <span class="divisionButtonText">{{ cnsltNm }}</span><!-- class 추가 2024.9.3 :chan -->
            <img v-show="!activeCnsltNo" src="@/assets/img/icon/ico_arrow_dropdown.png" width="24" height="24" alt="" />
            <img v-show="activeCnsltNo" src="@/assets/img/icon/ico_arrow_dropdown_wh.png" width="24" height="24" alt="" />
          </div>
          <div class="select-box fexTy3 bgcolor-2 bdTy12 bd-radius-20 pl5 pr5 pt3 pb3" :class="{ 'bgcolor-4': activeKcisCoCd, 'crTy-white': activeKcisCoCd }" @click="$refs.bottomSelectKcisCoCd.toggleItem()" style="border-color: #b3b3b3 !important">
            <span class="divisionButtonText">{{ kcisCoCdNm }}</span>
            <img v-show="!activeKcisCoCd" src="@/assets/img/icon/ico_arrow_dropdown.png" width="24" height="24" alt="" />
            <img v-show="activeKcisCoCd" src="@/assets/img/icon/ico_arrow_dropdown_wh.png" width="24" height="24" alt="" />
          </div>
          <div class="select-box fexTy3 bgcolor-2 bdTy12 bd-radius-20 pl5 pr5 pt3 pb3" :class="{ 'bgcolor-4': activeYm, 'crTy-white': activeYm }" style="border-color: #b3b3b3 !important" @click="fn_OpenPopup341">
            <span class="divisionButtonText">{{ ymNm }}</span>
            <img v-show="!activeYm" src="@/assets/img/icon/ico_arrow_dropdown.png" width="24" height="24" alt="" />
            <img v-show="activeYm" src="@/assets/img/icon/ico_arrow_dropdown_wh.png" width="24" height="24" alt="" />
          </div>
          <div class="select-box fexTy3 bgcolor-2 bdTy12 bd-radius-20 pl5 pr5 pt3 pb3" :class="{ 'bgcolor-4': activeComp, 'crTy-white': activeComp }" style="border-color: #b3b3b3 !important" @click="fn_OpenPopup342">
            <span class="divisionButtonText">{{ compNm }}</span>
            <img v-show="!activeComp" src="@/assets/img/icon/ico_arrow_dropdown.png" width="24" height="24" alt="" />
            <img v-show="activeComp" src="@/assets/img/icon/ico_arrow_dropdown_wh.png" width="24" height="24" alt="" />
          </div>
          <div class="select-box fexTy3 bgcolor-2 bdTy12 bd-radius-20 pl5 pr5 pt3 pb3" :class="{ 'bgcolor-4': activeAttr, 'crTy-white': activeAttr }" style="border-color: #b3b3b3 !important" @click="fn_OpenPopup343">
            <span class="divisionButtonText">{{ attrNm }}</span>
            <img v-show="!activeAttr" src="@/assets/img/icon/ico_arrow_dropdown.png" width="24" height="24" alt="" />
            <img v-show="activeAttr" src="@/assets/img/icon/ico_arrow_dropdown_wh.png" width="24" height="24" alt="" />
          </div>
        </div>
      </ur-box-container>

      <div v-if="!isEmptyView" class="w100 fexTy3 pal10 ns-check-sum"> <!-- ns-check-sum 추가 2024.9.5 :chan -->
        <div class="list-item__contents">
          <div class="list-item__contents__title">
            <span class="sum">총 <span class="crTy-blue3">{{ dataList.length }}</span>건</span>
          </div>
        </div>
        <p class="untact-txt mt0 fexTy1 crTy-bk7" style="gap: 5px; font-size: 0.875rem"> <!-- style 추가 2024.9.2 :chan -->
          <span class="ns-tool-tip">
            <mo-icon id="location0" icon="msp-tool-tip">tool-tip</mo-icon><!-- icon으로 교체 class 2024.9.5 :chan -->
          </span>
          금액 클릭 시, 상세보장금액 표출 <!-- 띄워쓰기 수정 2024.9.2 :chan -->
        </p>
      </div>
    </template>

    <div class="sticky-area">
      <!-- class="motableInquiry" 추가 2024.9.4 :chan -->
      <mo-table 
        v-show="!isEmptyView"
        ref="tableRef"
        :topHeaders="topHeaders"
        :columns="columns"
        :dataSource="dataList"
        :tooltips="tooltips"
        :stickyCols="1"
        :stickyHeaderPosition="0"
        class="motableInquiry"
      >
        <!-- 테이블 툴팁 -->
        <template #table-tooltip_sort>
          <div class="mo-table_tooltip-text">클릭하면 오름차순으로 볼 수 있어요.</div>
          <mo-icon icon="msp-close-bold" class="mo-table_tooltip-close-icon" @click.stop="fn_CloseTooltip(0)">close</mo-icon>
        </template>
        <template #table-tooltip_amt>
          <div class="mo-table_tooltip-text">클릭하면 상세보장금액을 볼 수 있어요.</div>
          <mo-icon icon="msp-close-bold" class="mo-table_tooltip-close-icon" @click.stop="fn_CloseTooltip(1)">close</mo-icon>
        </template>
        <!-- 테이블 커스텀 헤더 (보장금액) -->
        <template #top-header-보장금액>
          <div class="fs12 fwn moTableInnerCaption"> <!-- 2024.9.2 :chan -->
            <div>
              <span>(단위: 만원)</span><br>
              <div class="rect"></div> <!-- 2024.9.2 :chan -->
              &nbsp;
              <span class="capText">보장금액0</span> <!-- 2024.9.2 :chan -->
            </div>
            <div class="moTableCheckAllWrap"> <!-- 2024.9.2 :chan -->
              <mo-checkbox v-model="isCheckAll" @click.stop="fn_CheckAll"></mo-checkbox>
            </div>
          </div>
        </template>
        <!-- 테이블 커스텀 헤더 (월보험료(타사)) -->
        <template #top-header-월보험료>
          <div class="fexTy1 w100 h100 text-right" @click.stop="fn_SortTableItems({ value: 'totPrm' })">
            <span class="fs14">월 보험료<br><span class="fs12">(타사)</span></span>
            <img v-show="sortObj.totPrm" src="@/assets/img/icon/ico_arrow_dropdown.png" width="24" height="24" alt="" />
          </div>
        </template>
        <!-- 테이블 커스텀 헤더 (가입건수(타사)) -->
        <template #top-header-가입건수>
          <div class="fexTy1 w100 h100 text-right" @click.stop="fn_SortTableItems({ value: 'totEntCnt' })">
            <span class="fs14">가입건수<br><span class="fs12">(타사)</span></span>
            <img v-show="sortObj.totEntCnt" src="@/assets/img/icon/ico_arrow_dropdown.png" width="24" height="24" alt="" />
          </div>
        </template>
        <!-- 테이블 커스텀 헤더 (보장급부) -->
        <template v-for="(header, idx) of headerList" v-slot:[fn_GetHeaderSlotName(header.value)]="{ data }">
          <div :key="`custom-header-${idx}`" class="fexTy1 w100 h100" :style="{ background: header.background, color: header.background ? '#fff' : null }" @click.stop="fn_SortTableItems(header)">
            <span :class="{ 'fs14': data.index !== 0, 'fwb': header.background }">{{ data.text }}</span>
            <img v-show="!header.children && sortObj[header.value]" src="@/assets/img/icon/ico_arrow_dropdown.png" width="24" height="24" alt="" />
          </div>
        </template>

        <!-- 테이블 커스텀 바디 -->
        <template v-for="(body, idx) of columns" v-slot:[fn_GetBodySlotName(body.value)]="{ data }">
          <!-- 고객명 -->
          <template v-if="body.value === 'custNm'">
            <div :key="`custom-body-${body.value}-${idx}`" class="moTableLeftHeader"> <!-- 2024.9.2 :chan -->
              <div class="moTableLeftHeaderCheckWrap" ><!-- 2024.9.2 :chan -->
                <mo-checkbox v-model="checkObj[data.chnlCustId]" @input="fn_CheckSelectedItemCount($event, data)" />
                <span class="name flex-u fwb" @click.stop="fn_OpenCustInfoBs(data.cnsltNo, data.chnlCustId, data[body.value])">{{ data[body.value] }}</span><!-- 2024.9.2 :chan -->
              </div>
            </div>
          </template>
          <!-- 월 보험료(타사) -->
          <template v-else-if="body.value === 'totPrm'">
            <!-- dataText class 추가 2024.9.5 :chan -->
            <div :key="`custom-body-${body.value}-${idx}`" class="text-right dataText" @click="fn_OpenCustprdtCoverAmt(data, body.value)">
              <div class="fs15">{{ data[body.value] }}</div> <!-- br없애고 div로 교체 2024.9.5 :chan -->
              <div class="fs13">{{ `(${data.otcomPrm})` }}</div>
            </div>
          </template>
          <!-- 가입건수(타사) -->
          <template v-else-if="body.value === 'totEntCnt'">
            <!-- dataText class 추가 2024.9.5 :chan -->
            <div :key="`custom-body-${body.value}-${idx}`" class="text-right dataText">
              <div class="fs15">{{ data[body.value] }}</div> <!-- br없애고 div로 교체 2024.9.5 :chan -->
              <div class="fs13">{{ `(${data.otcomEntCnt})` }}</div>
            </div>
          </template>
          <template v-else>
            <div :key="`custom-body-${body.value}-${idx}`" class="fexTy1 w100 h100" style="justify-content: flex-end !important;" 
                :style="{ background: data[body.value] ? null : '#Fee3dc', paddingRight: data[body.value] ? null : '10px' }" @click="fn_OpenCustprdtCoverAmt(data, body.value)">
              <span class="fs15">{{ data[body.value] ? data[body.value] : '-' }}</span>
            </div>
          </template>
        </template>
      </mo-table>
      <button v-show="showRightScroll" class="icon-right" style="position: fixed; top: 65%;" @click="fn_ScrollToRight"><i></i></button>
      <button v-show="showDownScroll" class="icon-down" style="position: fixed;" @click="fn_ScrollToDown"><i></i></button>

      <!-- NoData 영역: start  -->
      <ur-box-container v-show="isEmptyView" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->
    </div>

    <ur-box-container alignV="start" componentid="" direction="column">
      <msp-bottom-select ref="bottomSelectKcisCoCd" :items="kcisCoCds" v-model="searchParams.kcisCoCd" bottom-title="구분" underline closeBtn scrolling @input="fn_InputKcisCoCd" />
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ************************************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
import MSPBottomAlert from "@/ui/_cmm/components/MSPBottomAlert";
import Msg from '@/systems/webkit/msg/msg'
import Screen from "@/systems/mixin/screen";
import cmConstants from '@/config/constants/cmConstants'
import MSPCM341P from '@/ui/cm/MSPCM341P' // 가입시기 필터 팝업
import MSPCM342P from '@/ui/cm/MSPCM342P' // 회사 필터 팝업
import MSPCM343P from '@/ui/cm/MSPCM343P' // 보장 필터 팝업
import MSPCM344P from '@/ui/cm/MSPCM344P' // 급부별 상품보장금액 팝업
import MSPCM345P from '@/ui/cm/MSPCM345P' // 컨설턴트 필터 팝업

export default {
  /************************************************************************************************
   * Vue 파일 속성 정의 영역
   ************************************************************************************************/
  // 현재 화면명
  name: "MSPCM340M",
  // 현재 화면 ID
  screenId: "MSPCM340M",
  mixins: [Screen],
  components: {
    MspBottomSelect
  },
  /************************************************************************************************
   * 화면 전역 변수 선언 영역
   ************************************************************************************************/
  data() {
    return {
      userInfo: {},

      pOrgData340M: {}, // 최근 조직 조회 데이터
      actionSlot: '', // 고객명 검색 슬롯
      inputCustNm: '', // 고객명 input
      searchParams: {
        cnsltNo: '', // 컨설턴트번호
        custNm: '', // 고객명
        kcisCoCd: '', // 구분: ''(전체), L03(당사), ZZZ(타사) 
        dateCd: '', // 가입시기코드
        strYm: '', // 가입시작년월
        endYm: '', // 가입종료년월
        comp: [], // 회사
      }, // 검색 데이터
      
      comps: [], // 회사 목록
      benfInfo: [], // 보장 목록
      headerList: [], // 보장 테이블 헤더 목록 (평탄화, 보장급부 전체 목록)
      flatHeaderList: [], // 보장 테이블 헤더 목록 (평탄화, 보장급부 선택 목록)
      treeHeaderList: [], // 보장 테이블 헤더 목록 (트리)
      kcisCoCds: [
        { value: '', text: '전체' },
        { value: 'L03', text: '당사' },
        { value: 'ZZZ', text: '타사' },
      ],
      
      dataList: [], // 조회용 Service IF VO
      originDataList: [], // 조회용 Service IF VO - 정렬 전 원본
      checkObj: {}, // 체크 여부 목록
      sortObj: {}, // 정렬 여부 목록
      selectItems: [], // 체크 데이터 목록

      isEmptyView: false, // 데이터 없음 화면
      isMngr: false, // 지점장 여부
      isCheckAll: false, // 전체 체크 여부
      attrCheckAll: false, // 보장급부 전체 체크 여부

      tableWidth: 0, // 테이블 길이
      bodyWidth: 0, // 바디(테이블) 영역 길이
      tableHeight: 0, // 테이블 높이
      bodyHeight: 0, // 바디(테이블) 영역 높이
      downScrollEl: null, // 하단 스크롤 엘리먼트
      rightScrollEl: null, // 우측 스크롤 엘리먼트
      showDownScroll: false, // 하단 스크롤 여부
      showRightScroll: false, // 우측 스크롤 여부

      tooltips: [], // 테이블 툴팁
      defaultHeaders: [
        [ // 1deps
          { text: '보장금액', key:"보장금액", rowspan: 3, colspan: 1 },
          { text: '계약현황', rowspan: 2, colspan: 2 },
        ],
        [ // 2deps
        ],
        [ // 3deps
          { text: '월 보험료(타사)', key:"월보험료" },
          { text: '가입건수(타사)', key:"가입건수" },
        ]
      ], // 테이블 헤더
      defaultColumns: [
        {
          value: 'custNm',
          text: '고객',
          type: 'string',
          width: '120',
          align: 'center',
          preventHighlight: true
        },
        {
          value: 'totPrm',
          text: '월 보험료(타사)',
          type: 'string',
          width: '80',
          align: 'right'
        },
        {
          value: 'totEntCnt',
          text: '가입건수(타사)',
          type: 'string',
          width: '65',
          align: 'right'
        }
      ], // 테이블 컬럼
    };
  },
  /************************************************************************************************
   * Life Cycle 함수 정의 영역
   ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    activeCnsltNo() {
      return !this.$bizUtil.isEmpty(this.pOrgData340M.cnslt);
    },
    activeKcisCoCd() {
      return this.searchParams.kcisCoCd;
    },
    activeYm() {
      return this.searchParams.dateCd && this.searchParams.dateCd !== '0';
    },
    activeComp() {
      return this.searchParams.comp.length;
    },
    activeAttr() {
      return this.flatHeaderList.length && !this.attrCheckAll;
    },
    cnsltNm() {
      return this.activeCnsltNo ? this.pOrgData340M.cnslt.label : '컨설턴트';
    },
    kcisCoCdNm() {
      return this.activeKcisCoCd ? this.kcisCoCds.filter(v => v.value === this.searchParams.kcisCoCd)[0].text : '구분';
    },
    ymNm() {
      return this.activeYm ? `${this.searchParams.strYm} ~ ${this.searchParams.endYm}` : '가입시기';
    },
    compNm() {
      return this.activeComp ? this.searchParams.comp.map(v => v.text).join(',') : '회사';
    },
    attrNm() {
      return this.activeAttr ? this.flatHeaderList.map(v => v.name).join(',') : '보장';
    },
    topHeaders() {
      let result = _.cloneDeep(this.defaultHeaders);

      this.treeHeaderList.forEach(parent => {
        let parentColspan = 0;
        parent.children.forEach(child => {
          result[1].push({ text: child.name, key: child.value, colspan: child.children.length });
          child.children.forEach(item => {
            result[2].push({ text: item.name, key: item.value });
            parentColspan++;
          });
        });
        result[0].push({ text: parent.name, key: parent.value, colspan: parentColspan });
      });
      return result;
    },
    columns() {
      let result = _.cloneDeep(this.defaultColumns);

      this.flatHeaderList.forEach(header => {
        result.push({
          value: header.value,
          text: header.name,
          type: 'string',
          width: '80',
          align: 'right'
        });
      })
      return result;
    },
  },
  watch: {
    selectItems(val) {
      if (this.dataList.length) {
        this.isCheckAll = val.length === this.dataList.length;
      } else {
        this.isCheckAll = false;
      }
    },
    dataList() {
      this.$nextTick(() => {
        this.tableHeight = document.querySelector('.mo-table').scrollHeight;
        this.fn_ShowDownScrollIcon(this.downScrollEl.scrollTop, this.downScrollEl.scrollHeight);

        this.tableWidth = document.querySelector('.mo-table thead tr').scrollWidth;
        this.fn_ShowRightScrollIcon(this.rightScrollEl.scrollLeft, this.rightScrollEl.scrollWidth);
      });
    },
  },
  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created() {
    this.userInfo = this.getStore('userInfo').getters.getUserInfo;
    if (!['FC', 'SA', 'TC'].includes(this.userInfo.chnlScCd)) { // FC채널 사용자가 아니라면 화면 나가기
      this.fn_OpenNotAuthPop();
      return;
    }
    this.fn_CreateActionSlot();
    this.fn_Init();

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if (this.$cmUtil.isSrchOrgCombo() === "Y") {
      this.isMngr = true;
    }
    
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack);
  },
  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog("MSPCM340M");

    this.inputCustNm = document.querySelector('.custom-action-slot-input').querySelector('input');
    this.bodyWidth = document.body.scrollWidth;
    this.bodyHeight = document.body.scrollHeight - document.querySelector('.mo-page__title-wrapper').scrollHeight;

    this.downScrollEl = document.getElementById(this.$refs.page.getScrollElementId());
    this.downScrollEl.addEventListener('scroll', e => {
      this.fn_ShowDownScrollIcon(e.target.scrollTop, e.target.scrollHeight);
    });
    
    this.rightScrollEl = this.$refs.tableRef.$refs['table-body'];
    this.rightScrollEl.addEventListener('scroll', e => {
      this.fn_ShowRightScrollIcon(e.target.scrollLeft, e.target.scrollWidth);
    });

    // 툴팁
    this.tooltips = [
      {
        name: 'sort',
        top: 0,
        left: 124,
        leftHeader: false,
        hide: this.isEmptyView || localStorage.getItem('READ_MSPCM340M_SORT_TOOLTIP'),
      },
      {
        name: 'amt',
        top: 120,
        left: 166,
        leftHeader: false,
        hide: this.isEmptyView || localStorage.getItem('READ_MSPCM340M_AMT_TOOLTIP'),
      }
    ];
  },
  activated () {
    if (!['FC', 'SA', 'TC'].includes(this.userInfo.chnlScCd)) { // FC채널 사용자가 아니라면 화면 나가기
      this.fn_OpenNotAuthPop();
      return;
    }

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack);
  },
  deactivated () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack);
  },
  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy() {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack);
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
   ************************************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_OpenNotAuthPop
     * @notice   권한 없음 팝업 오픈
     *********************************************************/
    fn_OpenNotAuthPop() {
      const lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          showCloseBtn: false,
          title: "",
          content: '운영되지 않는 기능입니다.',
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_AlertPop)
            this.$router.push({ name: 'MSPBC002M' });
          }
        }
      });
    },
    /*********************************************************
     * @function fn_CreateActionSlot
     * @notice   커스텀 액션 슬롯 생성
     *********************************************************/
    fn_CreateActionSlot() {
      const lv_Vm = this;
      this.actionSlot = {
        actionComponent: { /* focusable class 추가, page 맨 하단에 정의, border --수정 2024.9.4 :chan */
          template: `<div slot="action" ref="refCustNmWrap" class="icon-wrapper icon-wrapper-type" style="width: 110px; border-bottom: 2px solid black;">     
                        <mo-text-field ref="refCustNm" type="none" class="mb0 flex-1 custom-action-slot-input" clearable placeholder="고객명" style="min-width: 75px;" @keyup.enter="fn_Search($event.target.value)" @focus="fn_Focus" @blur="fn_Blur" />                                          
                        <mo-icon @click='fn_Search($refs.refCustNm.$refs.input.value)'>search</mo-icon>
                      </div>`,
          methods: {          
            fn_Search(e) {
              lv_Vm.refCustNm = this.$refs.refCustNm
              lv_Vm.searchParams.custNm = e;
              lv_Vm.fn_SelCustBenfInqrList();
            },
            fn_Focus(e) {
              this.$refs.refCustNmWrap.classList.add('focusable');
            },
            fn_Blur(e) {
              this.$refs.refCustNmWrap.classList.remove('focusable');
            }
          }
        }
      }
    },
    /*********************************************************
     * @function fn_Init
     * @notice   초기화 함수
     *********************************************************/
    fn_Init() {
      if (this.inputCustNm) this.inputCustNm.value = '';
      this.attrCheckAll = false;
      this.selectItems = [];
      this.pOrgData340M = {};
      this.comps = _.cloneDeep(cmConstants.KCIS_CO_CD);
      this.benfInfo = _.cloneDeep(cmConstants.BENF_INFO_GRID);
      this.headerList = this.fn_RecursiveFlattenTree(this.benfInfo); // 보장급부 헤더 세팅 
      this.flatHeaderList = this.headerList.filter(v => v.checked); // 기본 선택 보장급부 세팅
      this.treeHeaderList = this.fn_ConvertCheckedTree(this.benfInfo); // 기본 트리 헤더 세팅
      this.searchParams = {
        cnsltNo: this.userInfo.userId,
        custNm: '',
        kcisCoCd: '',
        dateCd: '',
        strYm: '',
        endYm: '',
        comp: [],
      };

      this.fn_SelCustBenfInqrList();
    },
    /*********************************************************
     * @function fn_SelCustBenfInqrList
     * @notice   당타사 조회 서비스 호출
     *********************************************************/
    fn_SelCustBenfInqrList() {
      this.fn_ClearSelectedStyle(); // 셀 선택 초기화
      this.sortObj = {}; // 헤더 정렬 초기화
      this.checkObj = {}; // 체크박스 초기화
      this.isCheckAll = false;
      this.$GnbManager.fn_SetGnbShow(false); // 하단 고객 툴바 닫기

      const trnstId = "txTSSCM17S1";
      const auth = "S";
      const pParams = {
        cnsltNo: this.searchParams.cnsltNo,
        custNm: this.searchParams.custNm,
        strYm: this.searchParams.strYm,
        endYm: this.searchParams.endYm,
        kcisCoCd: this.searchParams.kcisCoCd ? this.searchParams.kcisCoCd : this.searchParams.comp.map(v => v.value).join(',')
      };

      // 지점장 조회시 구분
      if (this.isMngr && this.activeCnsltNo) {
        pParams.cnsltNo = this.pOrgData340M.cnslt.key // 선택한 컨설턴트번호
      }

      this.isLoading = true;
      this.post(this, pParams, trnstId, auth)
        .then((response) => {
          if (response.body && response.body.iCCustBenfListVO) {
            this.dataList = response.body.iCCustBenfListVO.map(item => {
              item.totPrm = this.$commonUtil.numberWithCommas(item.totPrm);
              item.otcomPrm = this.$commonUtil.numberWithCommas(item.otcomPrm);
              item.iCBenfInfoVO?.forEach(v => {
                item[v.attrNm] = this.$commonUtil.numberWithCommas(v.coltrAmt);
              });
              return item;
            });
            this.originDataList = _.cloneDeep(this.dataList);
            this.isEmptyView = !this.dataList.length;
          } else {
            this.dataList = [];
            this.isEmptyView = true;
          }
        })
        .catch((error) => {
          this.isEmptyView = true;
          window.vue.error(error);
          this.getStore("confirm").dispatch("ADD", error);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    /*********************************************************
     * @function fn_RecursiveFlattenTree
     * @notice   트리 데이터 평탄화
     *********************************************************/
    fn_RecursiveFlattenTree(tree, flat = []) {
      tree.forEach(item => {
        flat.push(item);
        if (item.children) this.fn_RecursiveFlattenTree(item.children, flat);
      });
      return flat;
    },
    /*********************************************************
     * @function fn_ConvertCheckedTree
     * @notice   체크된 트리만 반환
     *********************************************************/
    fn_ConvertCheckedTree(tree) {
      return _.cloneDeep(tree).reduce((acc, cur) => {
        cur.children = cur.children.reduce((acc2, cur2) => {
          cur2.children = cur2.children.filter(v => cur2.checked || v.checked);
          return cur2.children.length ? [...acc2, cur2] : acc2;
        }, [])
        return cur.children.length ? [...acc, cur] : acc;
      }, []);
    },
    /*********************************************************
     * @function fn_GetHeaderSlotName
     * @notice   동적 헤더 slot명 반환
     *********************************************************/
    fn_GetHeaderSlotName(name) {
      return `top-header-${name}`;
    },
    /*********************************************************
     * @function fn_GetBodySlotName
     * @notice   동적 바디 slot명 반환
     *********************************************************/
    fn_GetBodySlotName(name) {
      return `body-${name}`;
    },
    /*********************************************************
     * @function fn_SortTableItems
     * @notice   테이블 정렬
     *********************************************************/
    fn_SortTableItems(data) {
      if (data.children) return;

      this.fn_CloseTooltip(0);
      this.fn_ClearSelectedStyle();
      this.dataList = _.cloneDeep(this.originDataList);

      if (this.sortObj[data.value]) { // 정렬 초기화
        this.sortObj[data.value] = false;
      } else { // 해당 컬럼 기준 오름차순 정렬
        // 월 보험료 or 가입건수인 경우
        this.sortObj.totPrm = false;
        this.sortObj.totEntCnt = false;
        if (data.value === 'totPrm' || data.value === 'totEntCnt') {
          this.sortObj[data.value] = true;
        }

        this.headerList.forEach(header => {
          this.sortObj[header.value] = header.value === data.value
        });
        this.dataList.sort((a, b) => {
          if (!a[data.value]) return -1;
          if (!b[data.value]) return 1;
          return parseInt(a[data.value].replace(/,/g, '')) - parseInt(b[data.value].replace(/,/g, ''));
        });
      }
      
      // 체크된 행에 행선택 클래스 적용
      this.dataList.forEach((item, idx) => {
        if (this.checkObj[item.chnlCustId]) {
          const tableRef = this.$refs.tableRef;
          if (tableRef) tableRef.selectRow(idx);
        }
      });
    },
    fn_CheckAll() {
      this.$nextTick(() => {
        this.fn_ClearSelectedStyle();
        
        this.dataList.forEach(item => {
          this.checkObj[item.chnlCustId] = this.isCheckAll;
        });
        this.selectItems = this.isCheckAll ? _.cloneDeep(this.dataList) : [];
        this.$cmUtil.setSelectedCustList(this, this.selectItems);
      });
    },
    /*********************************************************
     * @function fn_CheckSelectedItemCount
     * @notice   체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     *********************************************************/
    fn_CheckSelectedItemCount(isCheck, data) {
      this.fn_ClearSelectedStyle(false);
      
      const tableRef = this.$refs.tableRef;
      if (isCheck && tableRef) {
        this.selectItems.push(data);

        tableRef.selectRow(data.index);
      } else {
        const index = this.selectItems.findIndex(v => v.chnlCustId === data.chnlCustId);
        this.selectItems.splice(index, 1);

        tableRef.deselectRow(data.index);
      }

      this.$cmUtil.setSelectedCustList(this, this.selectItems);
      this.$forceUpdate();
    },
    /******************************************************************************
     * @function fn_OpenCustInfoBs
     * @notice   네임UI 바텀시트 (고객명터치시)
     ******************************************************************************/
    fn_OpenCustInfoBs (cnsltNo, chnlCustId, custNm) {
      const nmUiObject = {
        cnsltNo,
        chnlCustId,
        custNm,
        contNo: '',
        parentId: 'MSPCM340M',
      }

      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject);
    },
    /******************************************************************************
     * @function fn_ClearSelectedStyle
     * @notice   셀 선택 스타일 초기화
     ******************************************************************************/
    fn_ClearSelectedStyle(isClearRowSelectedWhole = true) {
      const tableRef = this.$refs.tableRef;
      if (!tableRef) return;
      
      tableRef.$data.selected.colIdx = -1;
      tableRef.$data.selected.rowIdx = -1;
      if (isClearRowSelectedWhole) tableRef.deselectAll();
    },
    /*********************************************************
     * @function fn_InputKcisCoCd
     * @notice   가입구분 필터 설정
     *********************************************************/
    fn_InputKcisCoCd() {
      this.comps = _.cloneDeep(cmConstants.KCIS_CO_CD);
      this.searchParams.comp = [];
      this.fn_SelCustBenfInqrList();
    },
    /*********************************************************
     * @function fn_OpenPopup341
     * @notice   가입시기 필터 팝업 호출
     *********************************************************/
    fn_OpenPopup341() {
      const popup341 = this.$bottomModal.open(MSPCM341P, {
        properties: {
          params: {
            dateCd: this.searchParams.dateCd,
            strYm: this.searchParams.strYm,
            endYm: this.searchParams.endYm
          }
        },
        listeners: {
          confirmPopup: async (pData) => {
            this.searchParams.dateCd = pData.dateCd;
            this.searchParams.strYm = pData.strYm;
            this.searchParams.endYm = pData.endYm;
            await this.$bottomModal.close(popup341);
            
            this.fn_SelCustBenfInqrList()
          },
          cancelPopup: () => {
            this.$bottomModal.close(popup341);
          }
        }
      })
    },
    /*********************************************************
     * @function fn_OpenPopup342
     * @notice   회사 필터 팝업 호출
     *********************************************************/
    fn_OpenPopup342() {
      const popup342 = this.$bottomModal.open(MSPCM342P, {
        properties: {
          params: {
            kcisCoCds: this.comps
          }
        },
        listeners: {
          confirmPopup: async (pData) => {
            this.searchParams.comp = pData;
            if (pData.length) this.searchParams.kcisCoCd = ''; // 회사 선택 시 가입구분 초기화
            
            await this.$bottomModal.close(popup342);
            
            this.fn_SelCustBenfInqrList()
          },
          cancelPopup: (originData) => {
            this.comps = originData;
            this.$bottomModal.close(popup342);
          }
        }
      })
    },
    /*********************************************************
     * @function fn_OpenPopup343
     * @notice   보장 필터 팝업 호출
     *********************************************************/
    fn_OpenPopup343() {
      const popup343 = this.$bottomModal.open(MSPCM343P, {
        properties: {
          params: {
            benfInfo: this.benfInfo
          }
        },
        listeners: {
          confirmPopup: async (pData) => {
            this.fn_ClearSelectedStyle(false);

            this.flatHeaderList = pData.flat;
            this.treeHeaderList = pData.tree;
            this.attrCheckAll = pData.checkAll;
            await this.$bottomModal.close(popup343);

            this.$nextTick(() => {
              this.tableWidth = document.querySelector('.mo-table thead tr').scrollWidth;
              this.fn_ShowRightScrollIcon(this.rightScrollEl.scrollLeft, this.rightScrollEl.scrollWidth);
            })
          },
          cancelPopup: (originData) => {
            this.benfInfo = originData;
            this.$bottomModal.close(popup343);
          }
        }
      })
    },
    /*********************************************************
     * @function fn_OpenPopup345
     * @notice   컨설턴트 필터 팝업 호출
     *********************************************************/
    fn_OpenPopup345() {
      const popup345 = this.$bottomModal.open(MSPCM345P, {
        properties: {
          params: {
            pOrgData: this.pOrgData340M, // 최근 조직 조회 데이터
          }
        },
        listeners: {
          confirmPopup: async (pData) => {
            this.pOrgData340M = pData;
            await this.$bottomModal.close(popup345);
            
            this.fn_SelCustBenfInqrList();
          },
          cancelPopup: () => {
            this.$bottomModal.close(popup345);
          }
        }
      })
    },
    /*********************************************************
     * @function fn_OpenCustprdtCoverAmt
     * @notice   급부별 상품보장금액 팝업 오픈
     *********************************************************/
    fn_OpenCustprdtCoverAmt(data, attrNm) {
      const price = data[attrNm];
      if (!price) return;
      
      this.fn_CloseTooltip(1);
      const popup344 = this.$bottomModal.open(MSPCM344P, {
        properties: {
          params: {
            cnsltNo: data.cnsltNo,
            chnlCustId: data.chnlCustId,
            custNm: data.custNm,
            attrNm: attrNm !== 'totPrm' ? attrNm : '',
            price
          }
        },
        listeners: {
          confirmPopup: () => {
            this.$bottomModal.close(popup344);
          },
          cancelPopup: () => {
            this.$bottomModal.close(popup344);
          }
        }
      })
    },
    /*********************************************************
     * @function fn_ShowRightScrollIcon
     * @notice   우측 테이블 스크롤 노출 여부
     *********************************************************/
    fn_ShowRightScrollIcon(scrollLeft, scrollWidth) {
      this.showRightScroll = this.tableWidth > this.bodyWidth && scrollLeft + this.bodyWidth < scrollWidth && this.dataList.length;
    },
    /*********************************************************
     * @function fn_ShowDownScrollIcon
     * @notice   하단 테이블 스크롤 노출 여부
     *********************************************************/
    fn_ShowDownScrollIcon(scrollTop, scrollHeight) {
      this.showDownScroll = this.tableHeight > this.bodyHeight && scrollTop + this.bodyHeight < scrollHeight && this.dataList.length;
    },
    /*********************************************************
     * @function fn_ScrollToRight
     * @notice   테이블 가로 스크롤 이동
     *********************************************************/
    fn_ScrollToRight() {
      this.$refs.tableRef.moveScroll(200);
    },
    /*********************************************************
     * @function fn_CloseTooltip
     * @notice   테이블 툴팁 닫기
     *********************************************************/
    fn_CloseTooltip(index) {
      localStorage.setItem(`READ_MSPCM340M_${index ? 'AMT' : 'SORT'}_TOOLTIP`, true);
      this.$refs.tableRef.closeTooltip(this.tooltips[index]);
    },
    /*********************************************************
     * @function fn_ScrollToDown
     * @notice   테이블 세로 스크롤 이동
     *********************************************************/
    fn_ScrollToDown() {
      this.downScrollEl.scrollTo({ top: this.downScrollEl.scrollTop + 200, behavior: 'smooth' });
    },
    /*********************************************************
     * @function fn_HistoryBack
     * @notice   뒤로가기 버튼
     *********************************************************/
    fn_HistoryBack() {
      if (this.selectItems.length) this.fn_confirm()
      else {
        // 조회기록 있는 경우
        if (this.searchParams.custNm || this.searchParams.kcisCoCd || this.searchParams.strYm || this.searchParams.endYm || this.searchParams.comp.length) {
          // 조회변수 초기화
          this.fn_Init()
          // 재조회
          this.fn_SelCustBenfInqrList()
        } else {
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }
    },
    /*********************************************************
     * @function fn_confirm
     * @notice   confirm popup
     *********************************************************/
    fn_confirm() {
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          type: 'B',
          content: '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?',
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_AlertPop)
            if (this.$route.query?._isMenuItem) {
              this.$router.push({name: 'MSPBC002M'})
              return
            }
            this.$router.go(-1)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_AlertPop)
          }
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.chat-bubble.type-M1.blu2::after {
  right: 35px; /* 수정 2024.8.30 */
}
.search-result-box::-webkit-scrollbar {
  display: none;
}
::v-deep {
  .mo-top-bar-action {
    .focusable { /* 고객명 검색어 입력란 focus 받았을 때 밑줄색 변경  --수정 2024.9.4 :chan */
      border-bottom: 2px solid #0042DC !important;
    }
  }
  .mo-text-field__input {
    border: none;
  }
  .mo-dropdown__title {
    display: none;
  }
  .mo-table {
    tbody {
      min-height: 200px;
    }
    td {
      &.sticky {
        .table-cell {
          background-color: #fff;
        }
      }
    }
    .table-header-cell {
      &.table-cell {
        background: #f4f5f9;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .col-selected, .row-selected {
      > .table-cell {
        background-color: #dcecfd !important;
      }
    }
    .col-selected.row-selected > .table-cell {
      background-color: #bedafb !important;
    }
    .row-selected-whole .table-cell {
      background-color: #dcecfd !important;
    }
    .mo-table_tooltip {
      min-width: 179px;
      background: -webkit-gradient(linear, left top, right top, color-stop(65%, #3190FF), to(#67BCE0));
      color: #fff;
      border-radius: 10px;
      padding: 12px 12px;
      box-shadow: 0 5px 5px rgba(0, 34, 255, 0.14);
      border: none;
    }
    .mo-table_tooltip-top {
      &::before {
        border: none;
      }
      &::after {
        border-bottom: #3190FF 10px solid;
        border-left: transparent 10px solid;
        border-right: transparent 10px solid;
        top: -9px;
        left: 40px;
      }
      &:nth-child(2) {
        &::after {
          left: 115px;
        }
      }
    }
    .mo-table_tooltip-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
    }
  }
}
</style>