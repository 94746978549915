/*
* 업무구분: 고객
* 화면 명: MSPCM341P
* 화면 설명: 가입시기 필터
* 화면 접근권한: 일반사용자
*/
<template>
   <ur-page-container class="msp" title="가입시기" :show-title="false" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container align-v="center" componentid="ur_box_container_001" direction="row" ref="_modal__title" class="ns-bs-title-login">
        <div class="title"><ur-text size="lg">가입시기</ur-text></div>
        <div class="btn-close" @click="$emit('cancelPopup')">X</div>
      </ur-box-container>

      <ur-box-container align-v="start" componentid="" direction="column" align-h="start" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="dateCd" solid primary class="chip-type-wrap" @input="fn_ChangeDateCd">
              <mo-segment-button v-for="(item, idx) in dateCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>      
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box msp" style="padding: 0"><!-- 2024.9.2 :chan -->
          <ur-box-container alignV="start" componentid="" direction="row" class="mt20 fexTy1 flex_nowrap">
            <mo-month-picker class="full" v-model="strYm" placeholder="YYYYMM" :max-date="searchStartMaxDate" :disabled="dateCd !== '3'" @input="fn_ChangeStartDate" />
            <span class="w35px txt-center">~</span>
            <mo-month-picker class="full" v-model="endYm" placeholder="YYYYMM" :min-date="searchEndMinDate" :max-date="searchEndMaxDate" :disabled="dateCd !== '3'" @input="fn_ChangeEndDate" />
          </ur-box-container>           
        </ur-box-container>
      </ur-box-container>
      <ur-box-container v-show="dateCd === '3'" alignV="start" componentid="ur_box_container_001" direction="column">
        <p class="untact-txt fs14 crTy-bk7" style="margin-top: 0; padding: 0 24px 10px; line-height: normal;">
          ※ 조회 기간 변경 시, 시작 월을 먼저 변경해주세요.
        </p>
      </ur-box-container>
      
      <ur-box-container align-v="start" componentid="ur_box_container_003" direction="row" align-h="end" class="ns-btn-relative-bs">
        <div class="relative-div">
          <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_ConfirmPop" class="ns-btn-round blue">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM341P",
    screenId: "MSPCM341P",
    props: {
      params: {
        type: Object,
        default: () => {}
      }
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        dateCd: '0',
        dateCds: [
          { value: '0', text: '전체' },
          { value: '1', text: '1달' },
          { value: '2', text: '3달' },
          { value: '3', text: '직접입력' },
        ],
        thisYear: '',
        todayMonth: [],
        prevOneMonth: [],
        prevThreeMonth: [],
        strYm: [],
        endYm: [],
        searchStartMaxDate: '1970-01-01', // 조회시작최대일자
        searchEndMinDate: '1970-01-01', // 조회종료최소일자
        searchEndMaxDate: '1970-01-01', // 조회종료최대일자
      };
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      if (this.params?.dateCd) this.dateCd = this.params.dateCd;

      this.$commonUtil.getServerTime().then(response => {
        const today = moment(response).format('YYYY-MM-DD');
        this.searchEndMaxDate = today;
        this.thisYear = today.split('-')[0];
        this.todayMonth = [this.thisYear, today.split('-')[1]];
        this.prevOneMonth = [moment(response).add(-1, 'months').format('YYYY'), moment(response).add(-1, 'months').format('MM')];
        this.prevThreeMonth = [moment(response).add(-3, 'months').format('YYYY'), moment(response).add(-3, 'months').format('MM')];

        if (this.params?.strYm) {
          this.strYm = [this.params.strYm.slice(0, 4), this.params.strYm.slice(4, 6)];
          this.searchEndMinDate = `${this.strYm.join('-')}-01`;
        } else {
          this.searchEndMinDate = today;
        }
        if (this.params?.endYm) {
          this.endYm = [this.params.endYm.slice(0, 4), this.params.endYm.slice(4, 6)];
          this.searchStartMaxDate = `${this.endYm.join('-')}-01`;
        } else {
          this.searchStartMaxDate = today;
        }
      });
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {     
      fn_ChangeDateCd(e) {
        switch (e) {
          case '0':
            this.strYm = [];
            this.endYm = [];
            break;
          case '1':
            this.strYm = [...this.prevOneMonth];
            this.endYm = [...this.todayMonth];
            break;
          case '2':
            this.strYm = [...this.prevThreeMonth];
            this.endYm = [...this.todayMonth];
            break;
          case '3':
            this.strYm = [this.thisYear, '01'];
            this.endYm = [...this.todayMonth];
            this.searchStartMaxDate = `${this.endYm.join('-')}-01`;
            this.searchEndMinDate = `${this.strYm.join('-')}-01`;
            break;
        }
      },
      fn_ChangeStartDate(date) {
        if (!date || !date.length) return;

        date[1] = date[1].padStart(2, '0') // IOS에서 month 두 글자 아닐 시 에러 발생 (기본 브라우저 차이) ex) 4 -> Error, 04 -> OK

        const startDate = moment(new Date(date.join('-')).toISOString());
        this.searchEndMinDate = startDate.format('YYYY-MM-DD');
      },
      fn_ChangeEndDate(date) {
        if (!date || !date.length) return;

        date[1] = date[1].padStart(2, '0') // IOS에서 month 두 글자 아닐 시 에러 발생 (기본 브라우저 차이) ex) 4 -> Error, 04 -> OK

        const endDate = moment(new Date(date.join('-')).toISOString());
        this.searchStartMaxDate = endDate.format('YYYY-MM-DD');
      },
      fn_ConfirmPop() {
        this.$emit('confirmPopup', { dateCd: this.dateCd, strYm: this.strYm.join(''), endYm: this.endYm.join('') });
      },
    }
  }
</script>

<style scoped>
.chip-type-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    gap: 10px;
}
.mo-segment-button {
  border-radius: 10px !important;
}
</style>